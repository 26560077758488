<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab> アポイント </v-tab>
      <v-tab> デモ </v-tab>
      <v-tab> 導入日程 </v-tab>
      <v-tab> 導入・取材待ち </v-tab>
      <!-- <v-tab> 構成提出待ち </v-tab>
      <v-tab> 制作中 </v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="pa-2">
        <v-btn class="ma-1" outlined @click="apoCreator.show = true"> <v-icon v-text="'mdi-plus'" left />新規アポ取得報告</v-btn>

        <XSheet :loading="apoData.loading">
          <template v-slot="{ loaded }">
            <v-sheet v-if="loaded">
              <v-card class="mb-2" v-for="(data, index) of apoDatas.filter((a) => !a.isConfirmDemoScheduleDate)" :key="index" outlined>
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-chip :color="$options.filters.apoStateColor(data.state)" small>
                            {{ data.state | apoState }}
                          </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <XChipMissed v-if="data.isMissed" class="mr-2" />
                          <XChipDummy v-if="data.facility.isDummy" class="mr-2" />
                          <span class="text-h6">{{ data.facility.name }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mb-2">
                          <span class="text-caption"> {{ data.facility.pref.name }}{{ data.facility.areaName }}</span>
                          <span class="ml-2">{{ data.demoTarget }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <a
                            class="pa-0 ma-0"
                            color="primary"
                            @click="
                              {
                                apoEditor.apoCode = data.code;
                                apoEditor.show = true;
                              }
                            "
                          >
                            <span>アポ</span>
                            <span v-if="data.apointer != null">({{ data.apointer.name }})</span>
                          </a>
                          <span> デモ日程 {{ data.demoSchedule | dateTime }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn
                      v-if="!data.isConfirmDemoScheduleDate"
                      class="ma-2"
                      color="primary"
                      outlined
                      @click="
                        {
                          apoDemoSchedule.apoCode = data.code;
                          apoDemoSchedule.show = true;
                        }
                      "
                      >デモ日程確認登録
                    </v-btn>
                    <v-btn
                      v-if="data.isConfirmDemoScheduleDate"
                      text
                      color="primary"
                      class="ma-2"
                      outlined
                      @click="
                        {
                          demoCreator.apoCode = data.code;
                          demoCreator.show = true;
                        }
                      "
                      >デモ結果報告
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-sheet>
          </template>
        </XSheet>
      </v-tab-item>
      <v-tab-item class="pa-2">
        <v-btn
          class="ma-1"
          outlined
          @click="
            {
              demoCreator.show = true;
              demoCreator.apoCode = null;
            }
          "
          ><v-icon v-text="'mdi-plus'" left />即デモ報告</v-btn
        >

        <XSheet :loading="demoData.loading">
          <template v-slot="{ loaded }">
            <template v-if="loaded">
              <v-card
                class="mb-2"
                v-for="(data, index) of demoDatas.filter((a) => {
                  if (a.apo != null && !a.apo.isConfirmDemoScheduleDate) return false;
                  if (a.apo != null && a.apo.isDemoScheduleModified) return false;
                  if (a.demo == null) return true;
                  if (a.demo != null && a.demo.resultType == 1 << 1) return false;
                  // キャンセルも表示しない
                  if (a.demo != null && a.demo.isCancelled) return false;
                  return true;
                })"
                :key="index"
                outlined
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle v-if="data.demo != null">
                          <v-chip :color="$options.filters.demoResultTypeColor(data.demo.resultType)" small>
                            {{ data.demo.resultType | demoResultType }}
                          </v-chip>
                          <v-chip v-if="data.demo.reportType == 1 << 1" color="primary" small>即デモ</v-chip>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          <XChipMissed v-if="data.isMissed || (data.apo != null && data.apo.isMissed)" class="mr-2" />

                          <XChipDummy v-if="data.facility.isDummy" class="mr-2" />
                          <span class="text-h6">{{ data.facility.name }}</span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-2">
                          <span class="text-caption"> {{ data.facility.pref.name }}{{ data.facility.areaName }}</span>
                          <span v-if="data.apo != null" class="ml-2">{{ data.apo.demoTarget }} </span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.apo != null">
                          <span v-if="data.apo != null">
                            <a
                              class="pa-0 ma-0"
                              color="primary"
                              @click="
                                {
                                  apoEditor.apoCode = data.apo.code;
                                  apoEditor.show = true;
                                }
                              "
                            >
                              <span> アポ</span>
                              <span v-if="data.apo.apointer != null"> :{{ data.apo.apointer.name }} </span>
                            </a>
                          </span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="data.demo != null">
                          <span>デモ</span>
                          <span v-if="data.demo.demointer != null">({{ data.demo.demointer.name }})</span>
                          <span> {{ data.demo.date | date }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-btn
                      v-if="data.demo != null"
                      color="primary"
                      class="ma-2"
                      outlined
                      @click="
                        {
                          demoEditor.demoCode = data.demo.code;
                          demoEditor.show = true;
                        }
                      "
                      >デモ結果編集
                    </v-btn>

                    <template v-else-if="data.apo != null && data.apo.state == 1 << 1">
                      <!-- <template v-else-if="data.apo != null"> -->
                      <v-btn
                        color="primary"
                        class="ma-2"
                        outlined
                        @click="
                          {
                            apoDemoSchedule.apoCode = data.apo.code;
                            apoDemoSchedule.show = true;
                          }
                        "
                        >デモ日程確認後変更</v-btn
                      >

                      <v-btn
                        color="primary"
                        class="ma-2"
                        outlined
                        @click="
                          {
                            demoCreator.apoCode = data.apo.code;
                            demoCreator.show = true;
                          }
                        "
                        >デモ結果報告
                      </v-btn>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </template>
        </XSheet>
      </v-tab-item>

      <v-tab-item class="pa-2">
        <XSheet :loading="hpCoverageData1.loading">
          <template v-slot="{ loaded }">
            <template v-if="loaded">
              <v-card class="mb-2" v-for="(data, index) of hpCoverageDatas1" :key="index" outlined>
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-chip :color="$options.filters.demoResultTypeColor(data.resultType)" small>
                            {{ data.resultType | demoResultType }}
                          </v-chip>

                          <v-chip class="ml-2" :color="$options.filters.demoSaleTypeColor(data.saleType)" small>{{
                            data.saleType | demoSaleType
                          }}</v-chip>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          <XChipDummy v-if="data.facility.isDummy" class="mr-2" />
                          <span class="text-h6">{{ data.facility.name }}</span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle class="mb-2">
                          <span class="text-caption"> ({{ data.facility.pref.name }}{{ data.facility.areaName }})</span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          <XChipCancelled v-if="data.cancelDate != null" class="ml-2" :label="$options.filters.date(data.cancelDate)" />

                          <a
                            class="pa-0 ma-0"
                            color="primary"
                            @click="
                              {
                                demoEditor.demoCode = data.code;
                                demoEditor.show = true;
                              }
                            "
                          >
                            <span>デモ</span>
                            <span v-if="data.demointer != null">({{ data.demointer.name }})</span>
                          </a>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <template v-if="data.isLeasePayment">
                      <div v-if="data.leasePayment == null">
                        <span class="ma-4 text-subtitle-2">リース審査待ち</span>
                      </div>
                      <div v-else-if="data.leasePayment.state != 1 << 2" text>
                        <span class="ma-4 text-subtitle-2">リース審査中</span>
                      </div>
                      <v-btn
                        v-else
                        class="ma-2"
                        color="primary"
                        outlined
                        @click="
                          {
                            hpCoverageCreator.demoCode = data.code;
                            hpCoverageCreator.show = true;
                          }
                        "
                      >
                        導入日設定
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        v-if="data.saleType != 1"
                        class="ma-2"
                        color="primary"
                        outlined
                        @click="
                          {
                            hpCoverageCreator.demoCode = data.code;
                            hpCoverageCreator.show = true;
                          }
                        "
                      >
                        導入日設定(リース以外)
                      </v-btn>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </template>
        </XSheet>
      </v-tab-item>
      <v-tab-item class="pa-2">
        <XSheet :loading="hpCoverageData2.loading">
          <template v-slot="{ loaded }">
            <template v-if="loaded">
              <v-card class="mb-2" v-for="(data, index) of hpCoverageDatas2" :key="index" outlined>
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <XChipDummy v-if="data.demo.facility.isDummy" class="mr-2" />
                          <span class="text-h6">{{ data.demo.facility.name }}</span>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="data.demo != null">
                          <XChipCancelled
                            v-if="data.demo.cancelDate != null"
                            class="ml-2"
                            :label="$options.filters.date(data.demo.cancelDate)"
                          />

                          <a
                            class="pa-0 ma-0"
                            color="primary"
                            @click="
                              {
                                demoEditor.demoCode = data.demo.code;
                                demoEditor.show = true;
                              }
                            "
                          >
                            <span>デモ</span>
                            <span v-if="data.demo.demointer">({{ data.demo.demointer.name }})</span>
                            <span>{{ data.demo.date | date }}</span>
                          </a>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle>
                          <span>導入取材日程: {{ data.scheduleDate | dateTime }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <template v-if="data.demo != null">
                      <v-btn
                        class="ma-2"
                        color="primary"
                        outlined
                        @click="
                          {
                            hpCoverageScheduleEditor.hpCoverageCode = data.code;
                            hpCoverageScheduleEditor.show = true;
                          }
                        "
                      >
                        導入取材日程変更
                      </v-btn>

                      <v-btn
                        class="ma-2"
                        color="primary"
                        outlined
                        @click="
                          {
                            hpCoverageEditor.hpCoverageCode = data.code;
                            hpCoverageEditor.show = true;
                          }
                        "
                      >
                        導入取材報告
                      </v-btn>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </template>
        </XSheet>
      </v-tab-item>
      <v-tab-item class="pa-2">
        <XSheet :loading="hpCreateData1.loading">
          <template v-slot="{ loaded }">
            <template v-if="loaded">
              <v-card v-for="(data, index) of hpCreateDatas1" :key="index">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <XChipDummy v-if="data.hpCoverage.demo.facility.isDummy" class="mr-2" />
                      {{ data.hpCoverage.demo.facility.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="data.hpCoverage != null">
                      <span>
                        <span v-if="data.hpCoverage.setuper != null">
                          導入者: <strong>{{ data.hpCoverage.setuper.name }} </strong>
                        </span>
                        <span v-if="data.hpCoverage.coverager != null">
                          取材者:<strong>{{ data.hpCoverage.coverager.name }} </strong>
                        </span>
                        <span v-if="data.hpCoverage.submiter != null">
                          構成担当者:<strong>{{ data.hpCoverage.submiter.name }}</strong>
                        </span>
                      </span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="data.hpCoverage != null">
                      <span>
                        導入取材日:<strong> {{ data.hpCoverage.date | date }}</strong>
                      </span>
                      <span>
                        構成提出予定日: <strong>{{ data.submitScheduleDate | date }}</strong>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text disabled>再取材</v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        {
                          hpCreateDateEditor.hpCreateCode = data.code;
                          hpCreateDateEditor.show = true;
                        }
                      "
                      >構成提出報告</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-expand-transition> </v-expand-transition>
              </v-card>
            </template>
          </template>
        </XSheet>
      </v-tab-item>
      <v-tab-item class="pa-2">
        <XSheet :loading="hpCreateData2.loading">
          <template v-slot="{ loaded }">
            <template v-if="loaded">
              <v-card v-for="(data, index) of hpCreateDatas2" :key="index">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <XChipDummy v-if="data.hpCoverage.demo.facility.isDummy" class="mr-2" />
                      {{ data.hpCoverage.demo.facility.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="data.hpCoverage != null">
                      <span>
                        <span v-if="data.hpCoverage.setuper != null">
                          導入者: <strong>{{ data.hpCoverage.setuper.name }} </strong>
                        </span>
                        <span v-if="data.hpCoverage.coverager != null">
                          取材者:<strong>{{ data.hpCoverage.coverager.name }} </strong>
                        </span>
                        <span v-if="data.hpCoverage.submiter != null">
                          構成担当者:<strong>{{ data.hpCoverage.submiter.name }}</strong>
                        </span>
                      </span>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="data.hpCoverage != null">
                      <span>
                        導入取材日: <strong>{{ data.hpCoverage.date | date }}</strong>
                      </span>
                      <span>
                        構成提出予定日: <strong>{{ data.submitScheduleDate | date }}</strong>
                      </span>
                      <span>
                        構成提出日: <strong>{{ data.date | date }}</strong>
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        {
                          hpCreateEditor.hpCreateCode = data.code;
                          hpCreateEditor.show = true;
                        }
                      "
                      >制作情報編集</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-expand-transition> </v-expand-transition>
              </v-card>
            </template>
          </template>
        </XSheet>
      </v-tab-item>
    </v-tabs-items>

    <!-- 新規アポ -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="apoCreator.show" inset>
        <ApoCreate :show="apoCreator.show" :observer="observer" @commit="onApoCreate">
          <template v-slot:close>
            <v-btn text color="warning" @click="apoCreator.show = false">閉じる</v-btn>
          </template>
        </ApoCreate>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- デモ日程確認 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="apoDemoSchedule.show" inset>
        <ApoDemoSchedule
          :show="apoDemoSchedule.show"
          :observer="observer"
          :apoCode="apoDemoSchedule.apoCode"
          @commit="onApoDemoSchedule"
          @error="apoDemoSchedule.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="apoDemoSchedule.show = false">閉じる</v-btn>
          </template>
        </ApoDemoSchedule>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- アポ内容編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="apoEditor.show" inset>
        <ApoEdit
          :show="apoEditor.show"
          :observer="observer"
          :apoCode="apoEditor.apoCode"
          @commit="onApoEdit"
          @error="apoEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="apoEditor.show = false">閉じる</v-btn>
          </template>
        </ApoEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 即デモ -->
    <!-- デモ結果報告 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="demoCreator.show" inset>
        <DemoCreate
          :show="demoCreator.show"
          :observer="observer"
          :apoCode="demoCreator.apoCode"
          @commit="onDemoCreate"
          @error="demoCreator.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="demoCreator.show = false">閉じる</v-btn>
          </template>
        </DemoCreate>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- デモ内容編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="demoEditor.show" inset>
        <DemoEdit
          :show="demoEditor.show"
          :observer="observer"
          :demoCode="demoEditor.demoCode"
          @commit="onDemoEdit"
          @error="demoEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="demoEditor.show = false">閉じる</v-btn>
          </template>
        </DemoEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 事前審査書提出 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="demoSubmitPreExamination.show" inset>
        <DemoSubmitPreExamination
          :show="demoSubmitPreExamination.show"
          :observer="observer"
          :demoCode="demoSubmitPreExamination.demoCode"
          @commit="onDemoSubmitPreExamination"
          @error="demoSubmitPreExamination.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="demoSubmitPreExamination.show = false">閉じる</v-btn>
          </template>
        </DemoSubmitPreExamination>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 取材日程報告 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="hpCoverageCreator.show" inset>
        <HPCoverageCreate
          :show="hpCoverageCreator.show"
          :observer="observer"
          :demoCode="hpCoverageCreator.demoCode"
          @commit="onHPCoverageCreate"
          @error="hpCoverageCreator.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="hpCoverageCreator.show = false">閉じる</v-btn>
          </template>
        </HPCoverageCreate>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 取材日程変更編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="hpCoverageScheduleEditor.show" inset>
        <HPCoverageScheduleEdit
          :show="hpCoverageScheduleEditor.show"
          :observer="observer"
          :hpCoverageCode="hpCoverageScheduleEditor.hpCoverageCode"
          @commit="onHPCoverageScheduleEdit"
          @commitClear="onHPCoverageScheduleEditClear"
          @error="hpCoverageScheduleEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="hpCoverageScheduleEditor.show = false">閉じる</v-btn>
          </template>
        </HPCoverageScheduleEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 導入取材入力 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="hpCoverageEditor.show" inset>
        <HPCoverageEdit
          :show="hpCoverageEditor.show"
          :observer="observer"
          :hpCoverageCode="hpCoverageEditor.hpCoverageCode"
          @commit="onHPCoverageEdit"
          @error="hpCoverageEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="hpCoverageEditor.show = false">閉じる</v-btn>
          </template>
        </HPCoverageEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 構成提出(導入取材完了報告時に構成提出をcreateしているためEdit)、取材日程変更編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="hpCreateDateEditor.show" inset>
        <HPCreateDateEdit
          :show="hpCreateDateEditor.show"
          :observer="observer"
          :hpCreateCode="hpCreateDateEditor.hpCreateCode"
          @commit="onHpCreateDateEditor"
          @error="hpCreateDateEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="hpCreateDateEditor.show = false">閉じる</v-btn>
          </template>
        </HPCreateDateEdit>
      </v-bottom-sheet>
    </ValidationObserver>

    <!-- 制作編集 -->
    <ValidationObserver v-slot="observer">
      <v-bottom-sheet persistent scrollable v-model="hpCreateEditor.show" inset>
        <HPCreateEdit
          :show="hpCreateEditor.show"
          :observer="observer"
          :hpCreateCode="hpCreateEditor.hpCreateCode"
          @commit="onHpCreateEditor"
          @error="hpCreateEditor.show = false"
        >
          <template v-slot:close>
            <v-btn text color="warning" @click="hpCreateEditor.show = false">閉じる</v-btn>
          </template>
        </HPCreateEdit>
      </v-bottom-sheet>
    </ValidationObserver>
  </div>
</template>

<script>
import ApoCreate from '@/components/apos/Create';
import ApoDemoSchedule from '@/components/apos/DemoSchedule';
import ApoEdit from '@/components/apos/Edit';

import DemoCreate from '@/components/demos/Create';
import DemoEdit from '@/components/demos/Edit';
import DemoSubmitPreExamination from '@/components/demos/SubmitPreExamination';

import HPCoverageCreate from '@/components/hpCoverages/Create';
import HPCoverageEdit from '@/components/hpCoverages/Edit';
import HPCoverageScheduleEdit from '@/components/hpCoverages/ScheduleEdit';

import HPCreateDateEdit from '@/components/hpCreates/DateEdit';
import HPCreateEdit from '@/components/hpCreates/Edit';

import { ApoStates, DemoResultTypes } from '@/types';

export default {
  components: {
    ApoCreate,
    ApoDemoSchedule,
    ApoEdit,

    DemoCreate,
    DemoEdit,
    DemoSubmitPreExamination,

    HPCoverageCreate,
    HPCoverageEdit,
    HPCoverageScheduleEdit,

    HPCreateDateEdit,
    HPCreateEdit,
  },
  computed: {
    // タブ情報はstoreに保存
    tab: {
      get() {
        return this.$store.getters.view.user.selectedProcess;
      },
      set(newValue) {
        this.$store.commit('setViewUserSelectedProcess', newValue);
      },
    },

    ApoStates() {
      return ApoStates;
    },
    DemoResultTypes() {
      return DemoResultTypes;
    },
    apoDatas() {
      let datas = this.apoData.datas.filter((a) => !a.isCancelled).filter((a) => !a.isMissed);

      return datas;
    },

    demoDatas() {
      let datas = this.demoData.datas.filter((a) => {
        if (a.apo != null && a.apo.isCancelled) return false;
        if (a.apo != null && a.apo.isMissed) return false;
        if (a.demo != null && a.demo.isCancelled) return false;
        if (a.demo != null && a.demo.isMissed) return false;
        return true;
      });
      return datas;
    },

    hpCoverageDatas1() {
      let datas = this.hpCoverageData1.datas.filter((a) => !a.isCancelled).filter((a) => !a.isMissed);
      return datas;
    },
    hpCoverageDatas2() {
      let datas = this.hpCoverageData2.datas.filter((a) => !a.isCancelled).filter((a) => !a.isMissed);
      return datas;
    },

    hpCreateDatas1() {
      let datas = this.hpCreateData1.datas.filter((a) => !a.isCancelled).filter((a) => !a.isMissed);
      return datas;
    },
    hpCreateDatas2() {
      let datas = this.hpCreateData2.datas.filter((a) => !a.isCancelled).filter((a) => !a.isMissed);
      return datas;
    },
  },
  data: () => ({
    // tab: 0,
    mail: {
      auto: true,
    },

    /** アポイントについて */
    apoData: {
      loading: true,
      datas: [],
    },

    apoCreator: {
      show: false,
    },

    apoDemoSchedule: {
      show: false,
      apoCode: null,
    },
    apoEditor: {
      show: false,
      apoCode: null,
    },

    /** デモについて */
    demoData: {
      show: false,
      loading: true,
      datas: [],
    },

    demoCreator: {
      show: false,
      apoCode: null,
    },

    demoEditor: {
      show: false,
      demoCode: null,
    },

    demoSubmitPreExamination: {
      show: false,
      demoCode: null,
    },

    /** 導入取材について */

    hpCoverageData1: {
      show: false,
      loading: true,
      datas: [],
    },
    hpCoverageData2: {
      show: false,
      loading: true,
      datas: [],
    },

    hpCoverageCreator: {
      show: false,
      demoCode: null,
    },

    hpCoverageScheduleEditor: {
      show: false,
      hpCoverageCode: null,
      isChangeSchedule: false,
    },

    hpCoverageEditor: {
      show: false,
      hpCoverageCode: null,
      isChangeSchedule: false,
    },

    /** 制作(構成提出～)取材について */

    hpCreateData1: {
      show: false,
      loading: true,
      datas: [],
    },
    hpCreateData2: {
      show: false,
      loading: true,
      datas: [],
    },

    hpCreateDateEditor: {
      show: false,
      demoCode: null,
    },

    hpCreateEditor: {
      show: false,
      demoCode: null,
    },
  }),
  methods: {
    load() {
      // ユーザ情報から初期選択状態
      if (this.$store.getters.user.isLogined) {
        this.get('employee', { employeeCode: this.$store.getters.user.code }).then((success) => {
          let data = success.data;
          //
          // let branch = data.branch;
          let branchCodes = data.branchs.map((a) => a.code).join(',');

          this.get('branches/apos', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.apoData.datas = data;
            this.apoData.loading = false;

            //
          });
          //
          this.get('branches/apoDemos', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.demoData.datas = data
              .filter((a) => {
                if (a.demo == null) return true; // 即デモは表示
                if (a.demo.resultType == 1 << 2) return false; // NGは除く
                return true;
              })
              .map((a) => ({ apo: a.apo, demo: a.demo, facility: a.facility, expansion: false }));
            //

            this.demoData.loading = false;
          });

          this.get('branches/hpCoverages/unScheduleDate', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.hpCoverageData1.datas = data;
            this.hpCoverageData1.loading = false;
          });

          this.get('branches/hpCoverages', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.hpCoverageData2.datas = data;
            this.hpCoverageData2.loading = false;
          });

          this.get('branches/hpCreates/unDate', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.hpCreateData1.datas = data;
            this.hpCreateData1.loading = false;
            //
          });

          this.get('branches/hpCreates', { branchCodes: branchCodes }).then((success) => {
            let data = success.data;
            this.hpCreateData2.datas = data;
            this.hpCreateData2.loading = false;
            //
          });
        });
      }
    },
    /**
     * 初期化時
     */
    onLoaded() {
      //
      this.load();
    },

    //
    onApoCreate(apoCode) {
      this.apoCreator.show = false;
      this.load();

      this.sendMailApo(apoCode);
    },

    onApoEdit(apoCode) {
      this.apoEditor.show = false;
      this.load();

      this.sendMailApo(apoCode);
    },

    onApoDemoSchedule(apoCode) {
      this.apoDemoSchedule.show = false;
      this.load();

      this.sendMailApo(apoCode);
    },

    //
    onDemoCreate(demoCode) {
      this.demoCreator.show = false;
      this.load();

      this.sendMailDemo(demoCode);
    },

    onDemoEdit(demoCode) {
      this.demoEditor.show = false;
      this.load();

      this.sendMailDemo(demoCode);
    },

    onDemoSubmitPreExamination(demoCode) {
      this.demoSubmitPreExamination.show = false;
      this.load();

      this.sendMailDemo(demoCode);
    },

    onHPCoverageCreate(hpCoverageCode) {
      this.hpCoverageCreator.show = false;
      this.load();

      this.sendMailHPCoverage(hpCoverageCode);
    },

    onHPCoverageScheduleEdit(hpCoverageCode) {
      this.hpCoverageScheduleEditor.show = false;
      this.load();

      this.sendMailHPCoverage(hpCoverageCode);
    },

    onHPCoverageScheduleEditClear() {
      this.hpCoverageScheduleEditor.show = false;
      this.load();
    },

    onHPCoverageEdit(hpCoverageCode) {
      this.hpCoverageEditor.show = false;
      this.load();

      this.sendMailHPCoverage(hpCoverageCode);
    },

    onLeasePaymentCreate(hpCoverageCode) {
      this.leasePaymentCreator.show = false;
      this.load();

      this.sendMailLeasePayment(hpCoverageCode);
    },

    onHpCreateDateEditor(hpCreateCode) {
      this.hpCreateDateEditor.show = false;
      this.load();

      this.sendMailHPCreate(hpCreateCode);
    },

    onHpCreateEditor(hpCreateCode) {
      this.hpCreateEditor.show = false;
      this.load();

      this.sendMailHPCreate(hpCreateCode);
    },
  },
  watch: {},
  mounted() {
    this.onLoaded();
  },
};
</script>