<template>
  <v-card outlined>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      新規アポ取得
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*アポ獲得日を入力してください' : '*アポ獲得日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="アポ獲得日">
                          <validation-provider v-slot="{ errors }" name="アポ獲得日" rules="required">
                            <DateTime label="アポ獲得日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.apoType)">{{
                      isEmptyValue(creator.apoType) ? '*顧客(報告区分)を入力してください' : '*顧客(報告区分)'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.apoType(creator.apoType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="顧客(報告区分)" rules="required">
                          <v-select
                            v-model="apoType"
                            :items="[
                              { text: $options.filters.apoType(1), value: 1 },
                              { text: $options.filters.apoType(1 << 1), value: 1 << 1 },
                              { text: $options.filters.apoType(1 << 2), value: 1 << 2 },
                              { text: $options.filters.apoType(1 << 3), value: 1 << 3 },
                            ]"
                            label="*顧客(報告区分)"
                            :rules="[(v) => !!v || '顧客(報告区分) は必須です']"
                            persistent-hint
                            hint="登録済みの既存顧客かわからない場合は次のステップの検索で確認できます"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            apoType = null;
                            creator.apoType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            newFacility = false;
                            creator.apoType = apoType;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.facilityName)">{{
                      isEmptyValue(creator.facilityName) ? '*顧客情報を登録してください' : '*顧客情報'
                    }}</XCheckLabel>
                    <span v-if="creator.facilityName != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.facilityName }}</span>
                      <span class="ml-2">{{ creator.facilityPref.name }}</span>
                      <span class="ml-2">{{ creator.facilityAreaName }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-title class="pa-0">
                        <v-spacer />
                        <v-switch v-if="creator.apoType != 1" v-model="newFacility" label="新規顧客として登録" required outlined dense />
                      </v-card-title>
                      <v-card-text>
                        <v-row v-if="creator.apoType == 1 || newFacility" align="center">
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="地方" rules="">
                              <v-select
                                v-model="facilityPrefArea"
                                :items="prefAreas"
                                label="地方"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                required
                                outlined
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
                              <v-select
                                v-model="facilityPref"
                                :items="facilityPrefs"
                                label="*都道府県"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '都道府県 は必須です']"
                                :error-messages="errors"
                                required
                                outlined
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="地域" rules="required|max:100">
                              <v-text-field
                                v-model="facilityAreaName"
                                label="*地域"
                                :rules="[(v) => !!v || '地域 は必須です']"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="8">
                            <validation-provider v-slot="{ errors }" name="顧客名" rules="required|max:100">
                              <v-text-field
                                v-model="facilityName"
                                label="*顧客名"
                                :rules="[(v) => !!v || '顧客名 は必須です']"
                                counter
                                :maxlength="100"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="電話番号" rules="required|max:50|phone">
                              <v-text-field
                                v-model="facilityTel"
                                label="*電話番号"
                                :rules="[(v) => !!v || '電話番号 は必須です']"
                                hint="ハイフン抜きで入力してください"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row v-else>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="電話番号" rules="max:50">
                              <!-- <v-text-field
                                v-model="facilityTel"
                                label="*電話番号 or 宿名"
                                :rules="[(v) => !!v || 'この項目は は必須です']"
                                hint="ハイフン抜きで入力してください"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                                @input="onFacilityTelInput"
                              /> -->
                              <v-text-field
                                v-model="facilitySearch"
                                label="*宿情報"
                                hint="宿名,電話番号(ハイフン抜き)など"
                                counter
                                :maxlength="50"
                                :error-messages="errors"
                                clearable
                                required
                                dense
                                @input="onSearchFacility(facilitySearch)"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="8">
                            <validation-provider v-slot="{ errors }" name="顧客名" rules="required">
                              <v-select
                                v-model="facility"
                                :items="facilities"
                                item-text="name"
                                item-value="code"
                                label="*顧客名"
                                :rules="[(v) => !!v || '顧客名 は必須です']"
                                :hint="`検索結果:${facilities.length}件 ${
                                  facility ? ' 選択中: [ ' + facility.name + '(' + facility.tel + ') ]' : ''
                                }`"
                                :error-messages="errors"
                                persistent-hint
                                return-object
                                clearable
                                required
                                outlined
                                dense
                                @input="facilityTel = facility.tel"
                              >
                                <template v-slot:item="{ item }">
                                  {{ item.name }}
                                  <span class="text-capition ml-2">
                                    (
                                    <span v-if="item.pref">{{ item.pref.name }}</span>
                                    {{ item.areaName }} {{ item.tel }}
                                    )
                                  </span>
                                </template>

                                <template v-slot:selection="{ item }">
                                  {{ item.name }}
                                  <span class="text-capition ml-2">
                                    (
                                    <span v-if="item.pref">{{ item.pref.name }}</span>
                                    {{ item.areaName }} {{ item.tel }}
                                    )
                                  </span>
                                </template>
                              </v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            {
                              facility = null;
                              creator.facility = null;
                              //
                              facilityTel = null;
                              facilityPrefArea = null;
                              creator.facilityName = null;
                              creator.facilityPref = {};
                              creator.facilityAreaName = null;
                              creator.facilityTel = null;
                            }
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            {
                              if (creator.apoType == 1 || newFacility) {
                                creator.facility = null;
                                creator.facilityName = facilityName;
                                creator.facilityPref = facilityPref;
                                creator.facilityAreaName = facilityAreaName;
                                creator.facilityTel = facilityTel;
                              } else {
                                creator.facility = facility;
                                creator.facilityName = facility.name;
                                creator.facilityPref = facility.pref;
                                creator.facilityAreaName = facility.areaName;
                                creator.facilityTel = facility.tel;
                              }
                              expansions++;
                            }
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.demoTarget)">{{
                      isEmptyValue(creator.apoType) ? '*デモ対象者を登録してください' : '*デモ対象者'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="creator.demoTarget"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <!-- <v-text-field
                    v-model="demoTarget"
                    label="*デモ対象者"
                    :rules="[(v) => !!v || 'デモ対象者 は必須です']"
                    hint="「社長」、「支配人」、「だんなさん」、「女将さん」など"
                    clearable
                    required
                    dense
                  /> -->

                        <validation-provider v-slot="{ errors }" name="デモ対象者" rules="required|max:100">
                          <v-combobox
                            v-model="demoTarget"
                            :items="['社長', '支配人', '旦那さん', '女将さん']"
                            label="デモ対象者(手入力可能です)"
                            :rules="[(v) => !!v || 'デモ対象者 は必須です']"
                            counter
                            :maxlength="100"
                            :error-messages="errors"
                            required
                            dense
                          ></v-combobox>
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoTarget = null;
                            creator.demoTarget = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoTarget = demoTarget;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.demoItemType)">{{
                      isEmptyValue(creator.demoItemType) ? '*デモ内容を登録してください' : '*デモ内容'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.demo_naiyou(creator.demoItemType)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="デモ内容" rules="required">
                          <v-select
                            v-model="demoItemType"
                            :items="[
                              { text: $options.filters.demo_naiyou(1), value: 1 },
                              { text: $options.filters.demo_naiyou(1 << 1), value: 1 << 1 },
                              { text: $options.filters.demo_naiyou(1 << 2), value: 1 << 2 },
                              { text: $options.filters.demo_naiyou(1 << 3), value: 1 << 3 },
                              { text: $options.filters.demo_naiyou(1 << 4), value: 1 << 4 },
                              { text: $options.filters.demo_naiyou(1 << 6), value: 1 << 6 },
                              { text: $options.filters.demo_naiyou(1 << 7), value: 1 << 7 },
                            ]"
                            label="*デモ内容"
                            :rules="[(v) => !!v || 'デモ内容 は必須です']"
                            :error-messages="errors"
                            required
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoItemType = null;
                            creator.demoItemType = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoItemType = demoItemType;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="isDate(creator.demoSchedule)">{{
                      isDate(creator.demoSchedule) ? '*デモ予定日時を登録してください' : '*デモ予定日時'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.dateTime(creator.demoSchedule)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="*デモ予定日時">
                          <validation-provider v-slot="{ errors }" name="デモ予定日時" rules="required">
                            <DateTime label="デモ予定日時" v-model="demoSchedule" :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ demoSchedule | dateTime }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            demoSchedule = null;
                            creator.demoSchedule = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.demoSchedule = demoSchedule;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.branch) && !isEmptyValue(creator.apointer) && !isEmptyValue(creator.team)">{{
                      isEmptyValue(creator.branch) || isEmptyValue(creator.apointer) || isEmptyValue(creator.team)
                        ? '*アポ担当情報を登録してください'
                        : '*アポ担当'
                    }}</XCheckLabel>
                    <span v-if="creator.branch != null && creator.apointer != null" class="ml-4 success--text">
                      <span v-if="creator.branch != null" class="ml-2">{{ creator.branch.name }}</span>
                      <span v-if="creator.apointer != null" class="ml-2">{{ creator.apointer.name }}</span>
                      <span v-if="creator.team != null" class="ml-2">{{ creator.team.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="支店" rules="required">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="*支店"
                                item-text="name"
                                item-value="id"
                                :rules="[(v) => !!v || '支店 は必須です']"
                                :error-messages="errors"
                                return-object
                                required
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="アポ担当" rules="">
                              <v-select
                                v-model="apointer"
                                :items="staffs"
                                label="アポ担当"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="4">
                            <validation-provider v-slot="{ errors }" name="チーム" rules="">
                              <v-select
                                v-model="team"
                                :items="teams"
                                label="チーム"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            branch = null;
                            apointer = null;
                            team = null;
                            creator.branch = null;
                            creator.apointer = null;
                            creator.team = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.branch = branch;
                            creator.apointer = apointer;
                            creator.team = team;
                            expansions++;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>
                    <XCheckLabel :value="!isEmptyValue(creator.memo)">
                      {{ isEmptyValue(creator.memo) ? '備考を登録してください' : '備考' }}
                    </XCheckLabel>
                    <span class="ml-2"> {{ creator.memo }}</span>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea v-model="memo" label="備考" counter :maxlength="1000" :error-messages="errors" clearable outlined />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels class="mt-2" v-model="expansions2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.confirmDemoScheduleDate)">デモ日程確認日</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.confirmDemoScheduleDate)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <p class="text-caption">
                          <span class="red--text">新規アポ報告の際はデモ日程確認日を入力しないで下さい。</span>
                          「デモ日程確認日」を入力して報告すると「デモ日程確認報告」としてメール配信されます。
                        </p>
                        <XFieldSet label="デモ日程確認日(省略可)">
                          <validation-provider v-slot="{ errors }" name="デモ日程確認日" rules="">
                            <DateTime label="デモ日程確認日" v-model="confirmDemoScheduleDate" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on"
                                  >{{ confirmDemoScheduleDate | date }}
                                </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            confirmDemoScheduleDate = null;
                            creator.confirmDemoScheduleDate = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.confirmDemoScheduleDate = confirmDemoScheduleDate;
                            expansions2 = null;
                          "
                          >設定</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    observer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    canCommit() {
      if (this.isEmptyValue(this.creator.date)) return false;
      if (this.isEmptyValue(this.creator.apoType)) return false;
      if (this.isEmptyValue(this.creator.facilityName)) return false;
      if (this.isEmptyValue(this.creator.facilityTel)) return false;
      if (this.isEmptyValue(this.creator.demoTarget)) return false;
      if (this.isEmptyValue(this.creator.demoItemType)) return false;
      if (this.isEmptyValue(this.creator.demoSchedule)) return false;
      if (this.isEmptyValue(this.creator.branch)) return false;
      // アポインター、チームは必須ではなくなった
      return true;
    },

    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }

      // 指定なしを先頭に加える
      staffs.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });

      return staffs;
    },

    teams() {
      let teams = [];
      if (this.branch != null) teams = this.branch.teams;

      // 指定なしを先頭に加える
      teams.unshift({
        id: null,
        code: null,
        name: '指定なし',
      });

      return teams;
    },

    facilityPrefs() {
      let prefs = this.dataContext.prefs;
      if (this.facilityPrefArea != null) {
        prefs = prefs.filter((a) => this.facilityPrefArea.prefIds.includes(a.prefID));
      }
      return prefs;
    },
  },
  data: () => ({
    //
    initializing: false,

    facilities: [],
    newFacility: false,
    //
    expansions: null, // multipleではいので単体
    expansions2: null, // 別項目用
    //
    date: new Date(),
    apoType: null,
    //
    facilityPrefArea: null,

    // 宿検索ワード(検索項目はapi側)
    facilitySearch: null,

    facilityName: null,
    facilityTel: null,
    facilityAreaName: null,
    facilityPref: {},
    //

    facility: null,

    // facility: {
    //   loading: false,
    //   name: null,
    //   pref: {},
    //   areaName: '',
    // },

    demoTarget: null,
    demoItemType: null,
    demoSchedule: null,
    confirmDemoScheduleDate: null,

    branch: null,
    apointer: null,
    team: null,

    memo: null,

    creator: {
      loading: false,

      date: null,
      apoType: null,

      //
      facilityName: null,
      facilityTel: null,
      facilityAreaName: null,
      facilityPref: null,

      demoTarget: null,
      demoItemType: null,
      demoSchedule: null,
      confirmDemoScheduleDate: null,

      branch: null,
      apointer: null,
      team: null,

      memo: null,
    },
  }),
  methods: {
    init() {
      //
      //
      this.expansions = null;
      //
      this.date = new Date();
      this.apoType = 1;
      //
      this.facilityName = null;
      this.facilityTel = null;
      this.facilityAreaName = null;
      this.facilityPref = {};
      //
      this.facility = null;
      // this.facility = {
      //   loading: false,
      //   name: null,
      //   pref: {},
      //   areaName: '',
      // };

      this.demoTarget = null;
      this.demoItemType = 1;
      this.demoSchedule = null;
      this.confirmDemoScheduleDate = null;

      this.memo = null;

      this.creator = {
        date: null,
        apoType: null,

        //
        facilityName: null,
        facilityTel: null,
        facilityAreaName: null,
        facilityPref: null,

        demoTarget: null,
        demoItemType: null,
        demoSchedule: null,
        confirmDemoScheduleDate: null,

        branch: null,
        apointer: null,
        team: null,

        memo: null,
      };

      // ユーザ情報から初期選択状態
      if (this.$store.getters.user.isLogined) {
        this.get('employee', { employeeCode: this.$store.getters.user.code }).then((success) => {
          let data = success.data;
          //
          let branch = data.branch;
          let team = data.team;

          //
          this.apointer = {
            id: data.code,
            name: data.name,
          };

          if (branch != null) {
            this.branch = {
              id: branch.code,
              name: branch.name,
              teams: branch.teams,
            };
          }

          if (team != null) {
            // 所属チームがbranch.teamsにいなければ足す
            if (this.branch.teams.filter((a) => a.id == team.id).length == 0) {
              // インスタンスを生成して設定
              this.branch.teams.push({
                id: team.id,
                name: team.name,
              });
            }

            this.team = {
              id: team.code,
              name: team.name,
            };
          }
        });
      }

      //
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();
      //
      this.initializing = false;
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;

      //
      let request = this.creator;
      request.date = this.convertDate(this.creator.date);
      request.demoSchedule = this.convertDate(this.creator.demoSchedule);
      request.confirmDemoScheduleDate = this.convertDate(this.creator.confirmDemoScheduleDate);

      this.post('apo', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          let data = success.data;
          this.$emit('commit', data.code);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onFacilityTelInput() {
      if (this.facilityTel.length <= 2) return;

      this.get('search/facility/tel', { tel: this.facilityTel }).then((success) => {
        //
        let data = success.data;
        this.facilities = data;
      });
    },

    onSearchFacility(word) {
      if (!word || word.length <= 1) return;

      this.get('search/facility', { word: word }).then((success) => {
        //
        let data = success.data;
        this.facilities = data;
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>