<template>
  <v-card flat>
    <v-card-title>
      <slot name="close"></slot>
      <v-spacer />
      制作編集
      <v-spacer />
      <v-btn color="primary" :loading="form.loading()" :disabled="!form.canSubmit()" @click="onCommit">登録</v-btn>
    </v-card-title>

    <v-card-text>
      <XSheet :loading="initializing">
        <template v-slot="{ loaded }">
          <div v-if="loaded">
            <template v-if="demo">
              <DemoResult :demo="demo" />
              <v-divider />
            </template>

            <template v-if="hpCoverage">
              <HPCoverageResult :hpCoverage="hpCoverage" />
              <v-divider />
            </template>

            <template v-if="hpCreate">
              <HPCreateResult :hpCreate="hpCreate" />
              <v-divider />
            </template>

            <v-divider />

            <v-expansion-panels v-model="expansions">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.date)">{{
                      isEmptyValue(creator.date) ? '*構成提出日を入力してください' : '*構成提出日'
                    }}</XCheckLabel>
                    <span class="ml-4 success--text" v-text="$options.filters.date(creator.date)"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <XFieldSet label="構成提出日">
                          <validation-provider v-slot="{ errors }" name="構成提出日" rules="">
                            <DateTime label="構成提出日" v-model="date" hideTime :error-messages="errors">
                              <template v-slot="{ click }"
                                ><v-btn text color="primary" dark v-bind="click.attrs" v-on="click.on">{{ date | date }} </v-btn>
                              </template>
                            </DateTime>
                          </validation-provider>
                        </XFieldSet>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            date = null;
                            creator.date = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.date = date;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.submiter)">{{
                      isEmptyValue(creator.setuper) ? '*構成者を登録してください' : '*構成者'
                    }}</XCheckLabel>
                    <span v-if="creator.submiter != null" class="ml-4 success--text">
                      <span v-if="creator.submiter != null" class="ml-2">{{ creator.submiter.name }}</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <p class="text-caption">この「支店」はデモ結果から参照しています。変更しても結果に反映されません。</p>

                            <validation-provider v-slot="{ errors }" name="支店" rules="">
                              <v-select
                                v-model="branch"
                                :items="dataContext.shitens"
                                label="支店"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <validation-provider v-slot="{ errors }" name="構成者" rules="">
                              <v-select
                                v-model="submiter"
                                :items="staffs"
                                label="構成者"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                return-object
                                required
                                outlined
                                dense
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            submiter = null;
                            creator.submiter = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.submiter = submiter;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <XCheckLabel :value="!isEmptyValue(creator.productionTime)">{{
                      isEmptyValue(creator.productionTime) ? '*構成作成時間を登録してください' : '*構成作成時間'
                    }}</XCheckLabel>
                    <span v-if="creator.productionTime != null" class="ml-4 success--text">
                      <span class="ml-2">{{ creator.productionTime }}時間</span>
                    </span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="制作時間" rules="required|max:2">
                          <v-text-field
                            type="number"
                            v-model.number="productionTime"
                            label="制作時間"
                            :rules="[(v) => !!v || '制作時間 は必須です']"
                            counter
                            :maxlength="2"
                            :error-messages="errors"
                            clearable
                            dense
                            suffix="時間"
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            productionTime = null;
                            creator.productionTime = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.productionTime = productionTime;
                            expansions++;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <XCheckLabel :value="!isEmptyValue(creator.memo)">{{
                    isEmptyValue(creator.memo) ? '備考を登録してください' : '備考'
                  }}</XCheckLabel>
                  <span class="d-inline-block text-truncate ml-2" style="max-width: 120px">{{ creator.memo }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ValidationObserver v-slot="observer2">
                    <v-card flat>
                      <v-card-text>
                        <validation-provider v-slot="{ errors }" name="備考" rules="max:1000">
                          <v-textarea
                            v-model="memo"
                            label="備考"
                            rows="3"
                            counter
                            :maxlength="1000"
                            :error-messages="errors"
                            clearable
                            outlined
                          />
                        </validation-provider>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          text
                          color="warning"
                          @click="
                            memo = null;
                            creator.memo = null;
                          "
                          >クリア
                        </v-btn>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          :disabled="observer2.invalid"
                          @click="
                            creator.memo = memo;
                            expansions = null;
                          "
                          >設定
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </ValidationObserver>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </template>
      </XSheet>
    </v-card-text>
  </v-card>
</template>

<script>
import DemoResult from '@/components/demos/Result';
import HPCoverageResult from '@/components/hpCoverages/Result';
import HPCreateResult from '@/components/hpCreates/Result';

export default {
  components: {
    DemoResult,
    HPCoverageResult,
    HPCreateResult,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },

    observer: {
      type: Object,
      required: true,
    },

    hpCreateCode: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        loading: () => this.creator.loading,
        disabled: () => this.creator.loading || this.initializing,
        canSubmit: () => !this.creator.loading && !this.initializing && !this.observer.invalid && this.canCommit,
        validate: () => this.observer.validate(),
        reset: () => {
          this.$nextTick(() => this.observer.reset());
        },
      };
    },
    staffs() {
      let staffs = this.dataContext.staffs;
      if (this.branch != null) {
        staffs = staffs.filter((a) => a.branchCode == this.branch.id);
      }
      return staffs;
    },

    teams() {
      if (this.branch != null) return this.branch.teams;
      return [];
    },

    demo() {
      if (this.hpCoverage != null) return this.hpCoverage.demo;
      return [];
    },

    canCommit() {
      if (this.isEmptyValue(this.creator.date)) return false;
      if (this.isEmptyValue(this.creator.submiter)) return false;
      if (this.isEmptyValue(this.creator.productionTime)) return false;
      return true;
    },
  },
  data: () => ({
    //
    initializing: false,

    hpCoverage: null,

    branch: null,

    hpCreate: {
      date: null,
      submiter: null,
      productionTime: null,
      memo: null,
    },
    //
    expansions: null, // multipleではいので単体

    date: null,
    submiter: null,
    productionTime: null,
    memo: null,

    creator: {
      loading: false,

      date: null,
      submiter: null,
      productionTime: null,
      memo: null,
    },
  }),
  methods: {
    init() {
      //
    },
    //
    onLoaded() {
      //
      this.initializing = true;
      //
      this.init();

      this.get('hpCreate', { hpCreateCode: this.hpCreateCode })
        .then((success) => {
          //
          let data = success.data;
          this.hpCreate = data;

          //
          let submiter = { id: null, name: null };
          if (data.submiter != null) {
            submiter.id = data.submiter.code;
            submiter.name = data.submiter.name;
          }

          //
          let productionTime = data.productionTime;

          this.hpCoverage = data.hpCoverage;
          this.date = data.date;
          this.submiter = submiter;
          this.productionTime = productionTime;
          this.memo = data.memo;

          this.creator.date = data.date;
          this.creator.submiter = submiter;
          this.creator.productionTime = productionTime;
          this.creator.memo = data.memo;

          //
          if (data.hpCoverage != null && data.hpCoverage.demo != null) {
            this.branch = {
              id: data.hpCoverage.demo.branch.code,
              name: data.hpCoverage.demo.branch.name,
            };
          }

          this.initializing = false;
        })
        .catch((ex) => {
          console.error('ex', ex);
          this.showError('データ取得に失敗しました');
          this.$emit('error');
        });
    },

    /**
     * フォーム決定イベント
     */
    async onCommit() {
      //
      if (!(await this.form.validate())) return;
      //
      if (!confirm('登録してよろしいですか？')) return;
      //
      let request = this.creator;
      request.hpCreateCode = this.hpCreateCode;
      request.date = this.convertDate(this.creator.date);

      // nullable
      request.productionTime = this.convertNumber(request.productionTime);
      //
      this.put('hpCreate/date', request)
        .then((success) => {
          //
          this.showSuccessSnackbar(success);
          //
          this.$emit('commit', this.hpCreateCode);
        })
        .catch((error) => this.showErrorSnackbar(error));
    },

    onCancelClicked() {
      this.$emit('cancel');
    },

    onFacilityTelInput() {
      if (this.facilityTel.length <= 2) return;

      this.facility.loading = true;
      this.get('search/facility/tel', { tel: this.facilityTel }).then((success) => {
        //
        let data = success.data;
        this.facilities = data;

        this.facility.loading = false;
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.onLoaded();
      },
      immediate: true, // 初期起動時にも監視させる
    },
  },
  mounted() {
    // this.onLoaded();
  },
};
</script>