<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <span> 構成情報 </span>

        <v-spacer />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="3">構成提出日</v-col>
              <v-col cols="9">{{ hpCreate.date | date }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">制作者</v-col>
              <v-col cols="9">{{ hpCreate.submiter.name }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">構成作成時間</v-col>
              <v-col cols="9">{{ hpCreate.productionTime }}時間</v-col>
            </v-row>
            <v-row>
              <v-col cols="3">備考</v-col>
              <v-col cols="9" v-html="$options.filters.md(hpCreate.memo)"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    hpCreate: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  data: () => ({}),
  methods: {},
  watch: {},
  mounted() {
    // this.onLoaded();
  },
};
</script>