var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._t("close"),_c('v-spacer'),_vm._v(" 制作編集 "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.form.loading(),"disabled":!_vm.form.canSubmit()},on:{"click":_vm.onCommit}},[_vm._v("登録")])],2),_c('v-card-text',[_c('XSheet',{attrs:{"loading":_vm.initializing},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loaded = ref.loaded;
return [(loaded)?_c('div',[(_vm.demo)?[_c('DemoResult',{attrs:{"demo":_vm.demo}}),_c('v-divider')]:_vm._e(),(_vm.hpCoverage)?[_c('HPCoverageResult',{attrs:{"hpCoverage":_vm.hpCoverage}}),_c('v-divider')]:_vm._e(),(_vm.hpCreate)?[_c('HPCreateResult',{attrs:{"hpCreate":_vm.hpCreate}}),_c('v-divider')]:_vm._e(),_c('v-divider'),_c('v-expansion-panels',{model:{value:(_vm.expansions),callback:function ($$v) {_vm.expansions=$$v},expression:"expansions"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.date)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.date) ? '*構成提出日を入力してください' : '*構成提出日'))]),_c('span',{staticClass:"ml-4 success--text",domProps:{"textContent":_vm._s(_vm.$options.filters.date(_vm.creator.date))}})],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('XFieldSet',{attrs:{"label":"構成提出日"}},[_c('validation-provider',{attrs:{"name":"構成提出日","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateTime',{attrs:{"label":"構成提出日","hideTime":"","error-messages":errors},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var click = ref.click;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',click.attrs,false),click.on),[_vm._v(_vm._s(_vm._f("date")(_vm.date))+" ")])]}}],null,true),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.date = null;
                          _vm.creator.date = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.date = _vm.date;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.submiter)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.setuper) ? '*構成者を登録してください' : '*構成者'))]),(_vm.creator.submiter != null)?_c('span',{staticClass:"ml-4 success--text"},[(_vm.creator.submiter != null)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.submiter.name))]):_vm._e()]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-caption"},[_vm._v("この「支店」はデモ結果から参照しています。変更しても結果に反映されません。")]),_c('validation-provider',{attrs:{"name":"支店","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.dataContext.shitens,"label":"支店","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","dense":""},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"構成者","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.staffs,"label":"構成者","item-text":"name","item-value":"id","error-messages":errors,"return-object":"","required":"","outlined":"","dense":""},model:{value:(_vm.submiter),callback:function ($$v) {_vm.submiter=$$v},expression:"submiter"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.submiter = null;
                          _vm.creator.submiter = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.submiter = _vm.submiter;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.productionTime)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.productionTime) ? '*構成作成時間を登録してください' : '*構成作成時間'))]),(_vm.creator.productionTime != null)?_c('span',{staticClass:"ml-4 success--text"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.creator.productionTime)+"時間")])]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"制作時間","rules":"required|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"制作時間","rules":[function (v) { return !!v || '制作時間 は必須です'; }],"counter":"","maxlength":2,"error-messages":errors,"clearable":"","dense":"","suffix":"時間"},model:{value:(_vm.productionTime),callback:function ($$v) {_vm.productionTime=_vm._n($$v)},expression:"productionTime"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.productionTime = null;
                          _vm.creator.productionTime = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.productionTime = _vm.productionTime;
                          _vm.expansions++;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('XCheckLabel',{attrs:{"value":!_vm.isEmptyValue(_vm.creator.memo)}},[_vm._v(_vm._s(_vm.isEmptyValue(_vm.creator.memo) ? '備考を登録してください' : '備考'))]),_c('span',{staticClass:"d-inline-block text-truncate ml-2",staticStyle:{"max-width":"120px"}},[_vm._v(_vm._s(_vm.creator.memo))])],1),_c('v-expansion-panel-content',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(observer2){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"備考","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"備考","rows":"3","counter":"","maxlength":1000,"error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"},on:{"click":function($event){_vm.memo = null;
                          _vm.creator.memo = null;}}},[_vm._v("クリア ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":observer2.invalid},on:{"click":function($event){_vm.creator.memo = _vm.memo;
                          _vm.expansions = null;}}},[_vm._v("設定 ")])],1)],1)]}}],null,true)})],1)],1)],1)],2):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }